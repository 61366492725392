import { filterMediaFromSrcset } from '@/components/frontends/Image/utils'

export default {
  head() {
    let PAGE_COVER =
      typeof this.cover === 'object'
        ? this.cover.srcset /** paginas estaticas */
        : typeof this.swiperSlides === 'object' &&
          Array.isArray(this.swiperSlides) &&
          this.swiperSlides.length > 0
        ? this.swiperSlides[0].media.data.srcset /** home */
        : typeof this.header === 'object' /** paginas search */
        ? this.header.media.srcset
        : ''

    if (typeof this.ogImage !== 'undefined') {
      PAGE_COVER = this.ogImage
    }

    const thumb = filterMediaFromSrcset(
      PAGE_COVER,
      process.env.APP_DEFAULT_COVER_PATH,
      280
    )

    const cover = thumb.url.includes('://')
      ? thumb.url
      : `${process.env.APP_URL}${thumb.url}`

    const title =
      this.meta && this.meta.title
        ? this.meta.title
        : this.$t(`${this.base}.meta.title`)

    const description =
      this.meta && this.meta.description
        ? this.meta.description
        : this.$t(`${this.base}.meta.description`)

    return {
      /* general metatags */
      title: `${title} | ${process.env.APP_NAME}`,
      description,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.$t(`${this.base}.meta.keywords`),
        },
        /* Open Graph metatags */
        {
          hid: 'og:url',
          property: 'og:url',
          content: `${process.env.APP_URL}${this.$route.path}`,
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: `${title} | ${process.env.APP_NAME}`,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: description,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: cover,
        },
        {
          hid: 'og:image:width',
          property: 'og:image:width',
          content: '1920',
        },
        {
          hid: 'og:image:height',
          property: 'og:image:height',
          content: '899',
        },
        /* Twitter metatags */
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: `${title} | ${process.env.APP_NAME}`,
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: description,
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: cover,
        },
        {
          hid: 'twitter:image:width',
          name: 'twitter:image:width',
          content: '1920',
        },
        {
          hid: 'twitter:image:height',
          name: 'twitter:image:height',
          content: '899',
        },
      ],
      /* canonical links */
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `${process.env.APP_URL}${this.$route.path}`,
        },
        {
          hid: 'alternate-default',
          rel: 'alternate',
          hreflang: 'x-default',
          href: `${process.env.APP_URL_EN_US}${this.$route.path}`,
        },
        {
          hid: 'alternate-en',
          rel: 'alternate',
          hreflang: 'en',
          href: `${process.env.APP_URL_EN_US}${this.$route.path}`,
        },
        {
          hid: 'alternate-es',
          rel: 'alternate',
          hreflang: 'es',
          href: `${process.env.APP_URL_ES_MX}${this.$route.path}`,
        },
        {
          hid: 'alternate-es-CO',
          rel: 'alternate',
          hreflang: 'es-CO',
          href: `${process.env.APP_URL_ES_CO}${this.$route.path}`,
        },
      ],
    }
  },
}
